import '../styles/loader.css'

interface LoadingProps {
    Background: string
}

function Loading(props: LoadingProps) {
    return (
        <div className='loader-wrapper' style={{ backgroundColor: props.Background }}>
            <span className='loader'></span>
        </div>
    )
}

export default Loading;