import TheCoveLogo from '../../assets/images/TheCove-Logo.png';
import CovePlusLogo from '../../assets/images/CovePlus-Logo.png';
import '../../styles/TopBannerIndex.css';

function TopBannerIndex() {
	return (
		<div style={{ minHeight: '80px', backgroundColor: 'white' }}>
			<div className="top-banner">
				<div className="centre-top-index">The Cove Analytics</div>
			</div>

			<div className="top-banner-split-view">
				<div className="the-cove-logo">
					<a href="https://cove.army.gov.au/">
						<img
							src={TheCoveLogo}
							alt="The Cove Logo"
							style={{ height: '75px !important' }}></img>
					</a>
				</div>
				<div className="cove-plus-logo">
					<a href="https://cove.army.gov.au/article/cove-armys-professional-development-program">
						<img src={CovePlusLogo} alt="Cove Plus Logo"></img>
					</a>
				</div>
			</div>
		</div>
	);
}

export default TopBannerIndex;
