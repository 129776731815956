import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AllTimeDashboardPage from './pages/AllTimeDashboardPage/AllTimeDashboardPage';
import CampaignPage from './pages/Campaign/CampaignPage/CampaignPage';
import CampaignsPage from './pages/Campaign/CampaignsPage/CampaignsPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import IndexMenuPage from './pages/IndexMenuPage/IndexMenuPage';
import LoginPage from './pages/LoginPage/LoginPage';
/*import NotFoundPage from './pages/NotFoundPage';*/

import {
	Chart as ChartJS,
	LineElement,
	PointElement,
	LinearScale,
	Title,
	CategoryScale,
	Legend,
	Tooltip,
} from 'chart.js';

ChartJS.register(
	LineElement,
	PointElement,
	LinearScale,
	Title,
	CategoryScale,
	Legend,
	Tooltip
);

function App() {
	return (
		<div className="app">
			<Router>
				<Routes>
					<Route path="/" element={<IndexMenuPage />} />
					<Route path="/alltime" element={<AllTimeDashboardPage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/campaigns" element={<CampaignsPage />} />
					<Route path="/campaign/*" element={<CampaignPage />} />
					{/* Need to figure out a way to detect /YYYY/MMM/ in url (no regex in react router v6)*/}
					<Route path="*" element={<DashboardPage />} />
					{/*
            Otherwise we go to page not found
            <Route path="*" element={<NotFoundPage />} /> 
            */}
				</Routes>
			</Router>
		</div>
	);
}

export default App;
