import TopBannerIndex from '../../components/index/TopBannerIndex';
import '../../App.css';
import IndexTable from '../../components/index/IndexTable';

function IndexMenuPage() {
	return (
		<>
			<TopBannerIndex />
			<IndexTable />
		</>
	);
}

export default IndexMenuPage;
