import '../../App.css';
import './AllTimeDashboardPage.scss';
import CovePlusLogo from '../../assets/images/CovePlus-Logo.png';
import LargeCardAllTime from '../../components/alltimedashboard/LargeCardAllTime/LargeCardAllTime';
import {
	AllTimeDataResponse,
	QuarterlyData,
} from '../../models/allTimeDataResponse';
import React, { useState } from 'react';
import { getAlltimeData } from '../../environment';
import LargeCardMultiLineAllTime from '../../components/alltimedashboard/LargeCardAllTime/LargeCardMultiLineAllTime';
import Loading from '../../components/Loading';
import TopBannerWithoutSwiper from '../../components/TopBanner/TopBannerWithoutSwiper';
import CoveLogoFullWidth from '../../components/CoveLogoFullWidth/CoveLogoFullWidth';

function AllTimeDashboardPage() {
	const [engagementsResponse, setEngagementsResponse] =
		useState<AllTimeDataResponse>({ Data: [] });
	const [engagementsResponse3Year, setEngagementsResponse3Year] =
		useState<AllTimeDataResponse>({ Data: [] });
	const [IsLoading, setIsLoading] = useState(false);

	React.useEffect(() => {
		// setIsLoading(true);
		fetch(`${getAlltimeData}`)
			.then((res) => res.json())
			.then((result) => {
				setEngagementsResponse(result);
				// setIsLoading(false);
			});
	}, []);

	React.useEffect(() => {
		setIsLoading(true);
		fetch(`${getAlltimeData}quarters=12`)
			.then((res) => res.json())
			.then((result) => {
				setEngagementsResponse3Year(result);
				setIsLoading(false);
			});
	}, []);

	const GetAllEngagements = (quarterlyData: QuarterlyData): number => {
		return (
			quarterlyData.PodcastListens +
			quarterlyData.VideoViews +
			quarterlyData.WebsiteHits +
			quarterlyData.UnitEnrollments
		);
	};

	const GetWebsiteHits = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.WebsiteHits;
	};

	const GetArticlesHits = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.ArticleViews;
	};

	const GetVideoViews = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.VideoViews;
	};

	const GetNewSubscriptions = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.NewSubscriptions;
	};

	const GetPodcasts = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.PodcastListens;
	};

	const GetAppDownloads = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.AppDownloads;
	};

	const GetProgramEnrollments = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.ProgramEnrollments;
	};

	const GetUnitEnrollments = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.UnitEnrollments;
	};

	const GetUnitCompleted = (quarterlyData: QuarterlyData): number => {
		return quarterlyData.UnitsCompleted;
	};

	if (IsLoading) {
		return <Loading Background="#F5F5F5" />;
	} else {
		return (
			<div className="alltimepage">
				<TopBannerWithoutSwiper
					Url="/"
					Title="The Cove Participation Statistics"
					SubTitle="All time data"
				/>
				<CoveLogoFullWidth />
				<div className="alltime-wrapper">
					<LargeCardAllTime
						dataFunction={GetAllEngagements}
						isAllTime={true}
						title="ENGAGEMENTS (ALL TIME)"
						tooltip="enagagements"
						isCurve={true}
						subHeader="Website Views&emsp;Video Views&emsp;Podcast Listens&emsp;Cove+ Unit Enrolments"
						mainData={engagementsResponse.Data}
					/>
					<LargeCardAllTime
						dataFunction={GetWebsiteHits}
						isAllTime={true}
						title="WEBSITE VIEWS (ALL TIME)"
						tooltip="views"
						isCurve={true}
						subHeader=""
						mainData={engagementsResponse.Data}
					/>

					<div className="two-container">
						<LargeCardAllTime
							dataFunction={GetArticlesHits}
							title="WEBSITE ARTICLE VIEWS"
							tooltip="views"
							isCurve={true}
							subHeader=""
							mainData={engagementsResponse3Year.Data}
						/>

						<LargeCardAllTime
							dataFunction={GetVideoViews}
							title="VIDEO VIEWS"
							tooltip="views"
							isCurve={true}
							subHeader=""
							mainData={engagementsResponse3Year.Data}
						/>
					</div>
					<div className="three-container">
						<LargeCardAllTime
							dataFunction={GetNewSubscriptions}
							title="NEW SUBSCRIPTIONS"
							tooltip="subscribers"
							isCurve={true}
							subHeader=""
							mainData={engagementsResponse3Year.Data}
						/>

						<LargeCardAllTime
							dataFunction={GetPodcasts}
							title="PODCAST LISTENS"
							tooltip="downloads"
							isCurve={true}
							subHeader=""
							mainData={engagementsResponse3Year.Data}
						/>

						<LargeCardAllTime
							dataFunction={GetAppDownloads}
							title="APP DOWNLOADS"
							tooltip="downloads"
							isCurve={true}
							subHeader=""
							mainData={engagementsResponse3Year.Data}
						/>
					</div>
				</div>
				<a href="https://cove.army.gov.au/" style={{ margin: '20px auto' }}>
					<img src={CovePlusLogo} alt="The Cove Logo"></img>
				</a>
				<div className="alltime-wrapper">
					<LargeCardMultiLineAllTime
						dataFunction1={GetProgramEnrollments}
						dataFunction2={GetUnitEnrollments}
						dataFunction3={GetUnitCompleted}
						title="COVE + STATISTICS"
						tooltip="views"
						isCurve={true}
						mainData={engagementsResponse3Year.Data}
					/>

					<div className="three-container">
						<LargeCardAllTime
							dataFunction={GetProgramEnrollments}
							title="PROGRAM ENROLMENTS"
							tooltip="enrolments"
							isCurve={true}
							subHeader=""
							mainData={engagementsResponse3Year.Data}
						/>

						<LargeCardAllTime
							dataFunction={GetUnitEnrollments}
							title="UNIT ENROLMENTS"
							tooltip="enrolments"
							isCurve={true}
							subHeader=""
							mainData={engagementsResponse3Year.Data}
						/>

						<LargeCardAllTime
							dataFunction={GetUnitCompleted}
							title="UNITS COMPLETED"
							tooltip="units"
							isCurve={true}
							subHeader=""
							mainData={engagementsResponse3Year.Data}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default AllTimeDashboardPage;
