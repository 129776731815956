import '../../../styles/LargeCard.css';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import { getMonthName } from '../../../helperFunctions/helpers';

function LargeCard(props: {
	title: any;
	stat: number;
	total: any;
	isUp: any;
	enrolHitText: any;
	month: number;
	isCurrent: boolean;
	stroke: number;
	totalDaysInCurrentMonth: number;
}) {
	let arrowImage;
	let arrowBackground;
	let arrowVisibility = true;
	let circleBorderColour;
	let bottomLineColor;
	if (props.isUp) {
		arrowImage = { backgroundImage: `url(${UpArrow})` };
		arrowBackground = '#1A9D11';
		circleBorderColour = '#1A9D11';
		bottomLineColor = 'rgb(26, 157, 17)';
	} else {
		arrowImage = { backgroundImage: `url(${DownArrow})` };
		arrowBackground = '#CC011A';
		circleBorderColour = '#CC011A';
		bottomLineColor = '#CC011A';
	}

	let statText = '0';

	const indicatorColor = props.isUp ? 'rgb(26, 157, 17)' : '#f2a415';

	if (props.stat < 1 && props.stat > -1) {
		statText = props.stat == 0 ? '0' : '<1';
		arrowVisibility = false;
		bottomLineColor = circleBorderColour = '#b1b1b1';
	} else {
		statText = Math.abs(Math.round(props.stat)).toString();
	}

	return (
		<div className="large-card">
			<div className="large-card-top">{props.title}</div>
			<div style={{ position: 'relative' }}>
				<svg
					className="svg-indicator"
					style={{ position: 'absolute', top: 30 }}>
					<circle
						className="svg-indicator-track"
						style={{ stroke: props.isCurrent ? '#b1b1b1' : circleBorderColour }}
					/>
					<circle
						className="svg-indicator-indication"
						style={{
							stroke: indicatorColor,
							visibility: props.isCurrent ? 'visible' : 'hidden',
							strokeDashoffset: props.stroke,
							strokeDasharray: 553,
						}}
					/>
				</svg>
				<span
					className="large-card-circle"
					style={{
						top: 0,
						left: 0,
					}}>
					<span
						style={{
							backgroundColor: arrowBackground,
							visibility:
								arrowVisibility && !props.isCurrent ? 'visible' : 'hidden',
						}}
						className="large-card-circle-arrow">
						<span
							style={arrowImage}
							className="large-card-circle-arrow-image"></span>
					</span>
					<div
						className="large-card-mid"
						style={{ color: props.isCurrent ?  '#f2a415' : 'black' }}>
						{props.isCurrent ? `${props.total}` : `${statText}%`}
					</div>
				</span>
			</div>
			<div
				className="large-card-bottom"
				style={{
					borderBottom: `3px solid ${
						props.isCurrent ? '#b1b1b1' : bottomLineColor
					}`,
				}}>
				<span>
					{props.isCurrent
						? `${getMonthName(props.month)} ${
								props.totalDaysInCurrentMonth == 1
									? '1'
									: `1 - ${getMonthName(props.month)} ${
											props.totalDaysInCurrentMonth
									  }`
						  }`
						: `${getMonthName(props.month)}
					: ${props.total} ${props.enrolHitText}`}
				</span>
			</div>
		</div>
	);
}

export default LargeCard;
