import '../styles/loader.css'

interface NoDataProps {
    Background: string
}

function NoData(props: NoDataProps) {
    return (
        <div className='loader-wrapper' style={{ backgroundColor: props.Background }}>
            <span style={{ fontWeight: 600, fontSize: '1.5rem' }}>Data is not yet available for in progress month</span>
        </div>
    )
}

export default NoData;