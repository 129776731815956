import { getPercentageChange, numberWithCommas } from '../helperFunctions/helpers'
import { TheCoveResponse } from '../models/theCoveResponse';
import { TopListItem } from './Interfaces';

interface TopList extends Array<TopListItem> { }

function MonthNumber(data: TheCoveResponse): number {
    if (data.MonthlyData[0].Year > data.MonthlyData[1].Year) {
        return data.MonthlyData[0].Month;
    } else if (data.MonthlyData[0].Year < data.MonthlyData[1].Year) {
        return data.MonthlyData[1].Month
    } else {
        return data.MonthlyData[0].Month > data.MonthlyData[1].Month ? data.MonthlyData[0].Month : data.MonthlyData[1].Month;
    }
}

function getPreviousMonth(month: number): number {
    if (month === 1) {
        return 12;
    } else {
        return month - 1;
    }
}

// Video views data
export function getVideoViewsPercentIncrease(data: TheCoveResponse): number {
    let prevTotal: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) { // needs +1 because this starts from zero
            prevTotal = e.VideoViews
        }
    })

    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            total = e.VideoViews
        }
    })
    VideoViewsIsUp = prevTotal < total
    return getPercentageChange(prevTotal, total)
}

export function getVideoViewsTotal(data: TheCoveResponse): string {
    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            total = e.VideoViews
        }
    })
    return numberWithCommas(total);
}

// App downloads data
export function getAppDownloadsPercentIncrease(data: TheCoveResponse): number {
    let prevTotal: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) { // needs +1 because this starts from zero
            prevTotal = e.AppDownloads
        }
    })

    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            total = e.AppDownloads
        }
    })
    AppDownloadsIsUp = prevTotal < total
    return getPercentageChange(prevTotal, total)
}

export function getAppDownloadsTotal(data: TheCoveResponse): string {
    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            total = e.AppDownloads
        }
    })
    return numberWithCommas(total);
}

// New subscriptions data
export function getNewSubscriptionsPercentIncrease(data: TheCoveResponse): number {
    let prevTotal: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) { // needs +1 because this starts from zero
            prevTotal = e.NewSubscriptions
        }
    })

    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            total = e.NewSubscriptions
        }
    })
    NewSubscriptionsIsUp = prevTotal < total
    return getPercentageChange(prevTotal, total)
}

export function getNewSubscriptionsTotal(data: TheCoveResponse): string {
    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            total = e.NewSubscriptions
        }
    })
    return numberWithCommas(total);
}

// Podcast listens data
export function getPodcastListensPercentIncrease(data: TheCoveResponse): number {
    let prevTotal: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) { // needs +1 because this starts from zero
            prevTotal = e.PodcastListens
        }
    })

    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            total = e.PodcastListens
        }
    })
    PodcastListensIsUp = prevTotal < total
    return getPercentageChange(prevTotal, total)
}

export function getPodcastListensTotal(data: TheCoveResponse): string {
    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            total = e.PodcastListens
        }
    })
    return numberWithCommas(total);
}

export function getTotalHitsThisMonth(data: TheCoveResponse): Array<number> {
    let array: number[] = [];
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            array = e.WebsiteHits.slice()
        }
    })
    return array;
}

export function getTotalHitsLastMonth(data: TheCoveResponse): Array<number> {
    let array: number[] = [];
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) { // needs +1 because this starts from zero
            array = e.WebsiteHits.slice()
        }
    })
    return array;
}

export function getTopArticlesList(data: TheCoveResponse): TopList {
    let articleList: TopList = [];
    let idCount = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) {
            e.PopularArticles.forEach(function (e) {
                articleList.push(
                    {
                        id: idCount,
                        start: e.Date,
                        end: e.Views,
                        text: e.Name,
                        img: e.Image,
                        link: e.Link,
                    }
                );
                idCount++
            })
        }
    })
    return articleList
}

export var VideoViewsIsUp: boolean;
export var AppDownloadsIsUp: boolean;
export var NewSubscriptionsIsUp: boolean;
export var PodcastListensIsUp: boolean;