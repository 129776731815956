import * as React from 'react';
import LineChart from './LineChart';
import '../../styles/TheCove.css';
import SmallCard from './cards/SmallCard';
import LargeCard from './cards/LargeCard';
import TopListCardArticles from './cards/TopListCardArticles';
import {
	splitToChunks,
	numberWithCommas,
	getPercentageChange,
	getMonthName,
} from '../../helperFunctions/helpers';
import { TopListItem } from '../../dataService/Interfaces';
import {
	getVideoViewsPercentIncrease,
	getVideoViewsTotal,
	getAppDownloadsPercentIncrease,
	getAppDownloadsTotal,
	getNewSubscriptionsPercentIncrease,
	getNewSubscriptionsTotal,
	getPodcastListensPercentIncrease,
	getPodcastListensTotal,
	getTotalHitsThisMonth,
	getTotalHitsLastMonth,
	VideoViewsIsUp,
	AppDownloadsIsUp,
	NewSubscriptionsIsUp,
	PodcastListensIsUp,
	getTopArticlesList,
} from '../../dataService/GetTheCoveData';
import { getTheCoveDataUrl } from '../../environment';
import { PageProps } from '../../models/pageProps';
import Loading from '../Loading';

function TheCove(props: PageProps) {
	const month = props.Month;
	const year = props.Year;

	// Dashboard numbers
	const [VideoViewsPercentIncrease, setVideoViewsPercentIncrease] =
		React.useState(0);
	const [VideoViewsTotal, setVideoViewsTotal] = React.useState('');
	const [AppDownloadsPercentIncrease, setAppDownloadsPercentIncrease] =
		React.useState(0);
	const [AppDownloadsTotal, setAppDownloadsTotal] = React.useState('');
	const [NewSubscriptionsPercentIncrease, setNewSubscriptionsPercentIncrease] =
		React.useState(0);
	const [NewSubscriptionsTotal, setNewSubscriptionsTotal] = React.useState('');
	const [PodcastListensPercentIncrease, setPodcastListensPercentIncrease] =
		React.useState(0);
	const [PodcastListensTotal, setPodcastListensTotal] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);

	const [ThisMonthTotalHits, setThisMonthTotalHits] = React.useState(0);
	const [ThisMonthHits, setThisMonthHits] = React.useState<number[]>([]);
	const [LastMonthTotalHits, setLastMonthTotalHits] = React.useState(0);
	const [LastMonthHits, setLastMonthHits] = React.useState<number[]>([]);
	const [TopArticles, setTopArticles] = React.useState<TopList>([]);

	const [stroke, setStroke] = React.useState(553);
	const [strokeSmall, setStrokeSmall] = React.useState(227);

	// Articles list
	interface TopList extends Array<TopListItem> {}

	React.useEffect(() => {
		setIsLoading(true);
		fetch(`${getTheCoveDataUrl}&month=${month}&year=${year}`)
			.then((res) => res.json())
			.then((result) => {
				setVideoViewsPercentIncrease(getVideoViewsPercentIncrease(result));
				setVideoViewsTotal(getVideoViewsTotal(result));
				setAppDownloadsPercentIncrease(getAppDownloadsPercentIncrease(result));
				setAppDownloadsTotal(getAppDownloadsTotal(result));
				setNewSubscriptionsPercentIncrease(
					getNewSubscriptionsPercentIncrease(result)
				);
				setNewSubscriptionsTotal(getNewSubscriptionsTotal(result));
				setPodcastListensPercentIncrease(
					getPodcastListensPercentIncrease(result)
				);
				setPodcastListensTotal(getPodcastListensTotal(result));
				setTopArticles(getTopArticlesList(result));

				const thisMonthHits = getTotalHitsThisMonth(result);
				let thisMonthTotalHits: number = 0;
				thisMonthHits.forEach((num) => {
					thisMonthTotalHits += num;
				});
				setThisMonthTotalHits(thisMonthTotalHits);
				setThisMonthHits(thisMonthHits);

				const lastMonthHits = getTotalHitsLastMonth(result);
				let lastMonthTotalHits: number = 0;
				lastMonthHits.forEach((num) => {
					lastMonthTotalHits += num;
				});
				setLastMonthTotalHits(lastMonthTotalHits);
				setLastMonthHits(lastMonthHits);

				setIsLoading(false);

				const dateinMonth = new Date(year, month, 0).getDate();
				setStroke(
					(1 - getTotalHitsThisMonth(result).length / dateinMonth) * 553
				);
				setStrokeSmall(
					(1 - getTotalHitsThisMonth(result).length / dateinMonth) * 227
				);
			});
	}, [month]);

	if (isLoading) return <Loading Background="#F5F5F5" />;

	return (
		<div>
			<div className="article-hits-chart-background">
				<div className="container">
					<LineChart
						title="Website Article Views"
						ytext="Article Hits"
						data1={ThisMonthHits}
						data2={LastMonthHits}
						label1={getMonthName(month).substring(0, 3)}
						label2={getMonthName(getPreviousMonth(month)).substring(0, 3)}
						yMax={5000}
						yMin={200}
						toolTipLabel="views"
						isCurrent={props.IsCurrent}
						toolTipColour="rgba(0, 0, 0, 0.8)"
					/>
				</div>
			</div>
			<div className="centre-flex-container">
				<div className="left-large-card">
					<LargeCard
						stroke={props.IsCurrent ? stroke : 553}
						title="WEBSITE ARTICLE VIEWS"
						stat={getPercentageChange(LastMonthTotalHits, ThisMonthTotalHits)}
						total={numberWithCommas(ThisMonthTotalHits)}
						isUp={LastMonthTotalHits < ThisMonthTotalHits}
						isCurrent={props.IsCurrent}
						totalDaysInCurrentMonth={ThisMonthHits.length}
						month={props.Month}
						enrolHitText="hits"
					/>
				</div>
				<div className="right-four-cards">
					<SmallCard
						isCurrent={props.IsCurrent}
						title="VIDEO VIEWS"
						stat={VideoViewsPercentIncrease}
						total={VideoViewsTotal}
						isUp={VideoViewsIsUp}
						stroke={props.IsCurrent ? strokeSmall : 227}
						totalDaysInCurrentMonth={ThisMonthHits.length}
						month={props.Month}
						isWide={false}
					/>
					<SmallCard
						isCurrent={props.IsCurrent}
						title="APP DOWNLOADS"
						stat={AppDownloadsPercentIncrease}
						total={AppDownloadsTotal}
						isUp={AppDownloadsIsUp}
						totalDaysInCurrentMonth={ThisMonthHits.length}
						month={props.Month}
						stroke={props.IsCurrent ? strokeSmall : 227}
						isWide={false}
					/>
				</div>
				<div className="right-four-cards">
					<SmallCard
						isCurrent={props.IsCurrent}
						title="NEW SUBSCRIPTIONS"
						stat={NewSubscriptionsPercentIncrease}
						total={NewSubscriptionsTotal}
						isUp={NewSubscriptionsIsUp}
						totalDaysInCurrentMonth={ThisMonthHits.length}
						month={props.Month}
						stroke={props.IsCurrent ? strokeSmall : 227}
						isWide={false}
					/>
					<SmallCard
						isCurrent={props.IsCurrent}
						title="PODCAST LISTENS"
						stat={PodcastListensPercentIncrease}
						total={PodcastListensTotal}
						isUp={PodcastListensIsUp}
						totalDaysInCurrentMonth={ThisMonthHits.length}
						month={props.Month}
						stroke={props.IsCurrent ? strokeSmall : 227}
						isWide={false}
					/>
				</div>
			</div>
			<div className="centre-flex-container">
				<TopListCardArticles
					title="MOST POPULAR ARTICLES"
					smallSpace={false}
					data={TopArticles}
					useLinks={true}
				/>
			</div>
		</div>
	);

	function getPreviousMonth(month: number): number {
		if (month === 1) {
			return 12;
		} else {
			return month - 1;
		}
	}
}

export default TheCove;
