import TheCoveLogo from '../../assets/images/TheCove-Logo.png';

function CoveLogoFullWidth() {
	return (
		<div
			style={{
				width: '100%',
				textAlign: 'center',
				marginTop: '20px',
				marginBottom: '20px',
			}}>
			<a href="https://cove.army.gov.au/" style={{ margin: '20px auto' }}>
				<img src={TheCoveLogo} alt="The Cove Logo"></img>
			</a>
		</div>
	);
}

export default CoveLogoFullWidth;
