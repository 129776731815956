import '../../styles/TopBanner.css';
import { Link } from 'react-router-dom';

export interface TopBannerWithoutSwiperProps {
	Title: string;
	SubTitle: string;
	Url: string;
}

function TopBannerWithoutSwiper(props: TopBannerWithoutSwiperProps) {
	return (
		<div
			className="top-banner"
			style={{ backgroundColor: 'white', minHeight: '80px' }}>
			<div className="centre-top" style={{ height: 'auto' }}>
				<Link to={props.Url === null ? '/' : props.Url}>
					<span>{props.Title}</span>
				</Link>
				<br />
				<span className="centre-top-month">{props.SubTitle}</span>
			</div>
		</div>
	);
}

export default TopBannerWithoutSwiper;
