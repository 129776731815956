// export const apiBaseUrl : string = "http://localhost:7071/"; 
// export const getTheCoveDataUrl:string =`${apiBaseUrl}api/GetTheCoveData?`;
// export const getCovePlusDataUrl:string =`${apiBaseUrl}api/GetCovePlusData?`;
// export const getTOCUrl:string =`${apiBaseUrl}api/GetTOC`;
// export const getAlltimeData:string =`${apiBaseUrl}api/GetEngagements?`;

//production
export const apiBaseUrl : string = "https://api.cove.army/"; 
export const getTheCoveDataUrl:string =`${apiBaseUrl}api/GetTheCoveData?code=QK-BH5QcPOlE4ZNOlfQdoT45lC3wC5oWzDHrWDK6bQNZAzFuIthufg==`;
export const getTOCUrl:string =`${apiBaseUrl}api/GetTOC?code=v0HA9FD7kRxhcv9L06k1jvlT5OprKpbKEHanqtkWZppWAzFudyCq2g==`;
export const getCovePlusDataUrl:string =`${apiBaseUrl}api/GetCovePlusData?code=QPDIsF7bG7CEwGqX_D7ALi9ia5v7zVK5ul68a7pSoXLSAzFuO7MeFQ==`;
export const getAlltimeData:string =`${apiBaseUrl}api/GetEngagements?code=yLCosouIBw0P19kqIpRvg75GQutRrJiDD3MCG_OIulx2AzFui9R-aw==&`;
export const login:string =`${apiBaseUrl}api/Login?code=zgdiQYdART8XQsGYVRY5euMQFtLdH9W2EEEeHteX36qHAzFum7J4aQ==`;
export const getCampaigns:string =`${apiBaseUrl}api/GetCampaigns?code=kiwY5Vd-NaltNyD_v-qSRFD9fNhKa6YUXgttMMjK7m-KAzFupXzYRg==`;
export const getCampaign:string =`${apiBaseUrl}api/GetCampaign?code=1P1qMJT4R4xzOSaMEUxtBuftUnXetdJsbrM3RxkEKg4oAzFu5YMGwQ==&`;