import * as React from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import TopBanner from '../../components/dashboard/TopBanner';
import '../../styles/Swipeable.css';
import { setGlobalState } from '../../state/state';
import { goToNextMonth, goToPreviousMonth } from '../../helperFunctions/navhelper';

import { useLocation, useNavigate } from 'react-router-dom';
import { MouseEvent, KeyboardEvent, useEffect } from 'react';

function DashboardPage() {

  const navigate = useNavigate();
  const location = useLocation();

  let date = location.pathname.split('/');
  // Update to selected year
  const [year, setYear] = React.useState(parseInt(date[1]));
  const [month, setMonth] = React.useState(parseInt(date[2]));
  const [isCurrent, setIsCurrent] = React.useState(false);

	const currentDate = new Date();	
  
  // Update month and year values based on numbers passed into URL path
  useEffect(() => {
    let date = location.pathname.split('/');
    // Update to selected year
    setYear(parseInt(date[1]));
    setMonth(parseInt(date[2]));

    setIsCurrent(
      currentDate.getFullYear() === parseInt(date[1]) &&
        currentDate.getMonth() + 1 === parseInt(date[2])
    );
  }, [location]);

  // Timer for mouse movement timeout
  var timeout: NodeJS.Timeout;
  // Detecting move mouse to show/hide nav buttons
  const handleMouseEvent = (e: MouseEvent) => {
    setTimeout(function () {

      e.preventDefault();
      setGlobalState("mouseMoving", true)

      clearTimeout(timeout);
      timeout = setTimeout(function () { setGlobalState("mouseMoving", false); }, 3000);
    }, 100); // Only update every 100ms
  };

  // Detect pressing arrow keys and navigate
  const handleKeyDown = (e: KeyboardEvent) => {
    console.log(e.key)
    if (e.key === 'ArrowLeft') {
      PreviousMonth()
    }
    else if (e.key === 'ArrowRight') {
      NextMonth()
    }
  }

  return (
    <div className='app' tabIndex={0} onKeyDown={handleKeyDown} onMouseMove={handleMouseEvent}>
      <TopBanner Month={month} Year={year}  IsCurrent={isCurrent}  />
      <Dashboard Month={month} Year={year}  IsCurrent={isCurrent}  />
    </div>
  );

  async function PreviousMonth() {
    await goToPreviousMonth(year, month, navigate);
  }

  async function NextMonth() {
    await goToNextMonth(year, month, navigate);
  }

}

export default DashboardPage;
