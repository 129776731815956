import { TooltipItem, TooltipModel } from 'chart.js';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import '../../styles/LineChart.css';
import { numberWithCommas } from '../../helperFunctions/helpers';

interface Props {
	title: string;
	ytext: string;
	data1: number[];
	data2: number[];
	label1: string;
	label2: string;
	yMax: number;
	yMin: number;
	toolTipLabel: string;
	toolTipColour: string;
	isCurrent: boolean;
}

interface State {}

class LineChart extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			title: props.title,
			ytext: props.ytext,
			data1: props.data1,
			data2: props.data2,
			label1: props.label1,
			label2: props.label2,
			yMax: props.yMax,
			yMin: props.yMin,
			toolTipLabel: props.toolTipLabel,
			toolTipColour: props.toolTipColour,
		};
	}
	render() {
		const data = {
			labels: Array.from(
				{
					length:
						this.props.data1.length > this.props.data2.length
							? this.props.data1.length
							: this.props.data2.length,
				},
				(_, i) => i + 1
			),
			datasets: [
				{
					label: this.props.label1,
					data: this.props.data1,
					fill: false,
					backgroundColor: 'rgb(23, 93, 208)',
					borderColor: 'rgba(23, 93, 208, 0.5)',
					tension: 0,
					pointHoverRadius: 4,
					pointRadius: 2,
					pointHitRadius: 7,
				},
				{
					label: this.props.label2,
					data: this.props.data2,
					fill: false,
					backgroundColor: 'rgb(26, 157, 17)',
					borderColor: 'rgba(26, 157, 17, 0.5)',
					tension: 0,
					pointHoverRadius: 4,
					pointRadius: 2,
					pointHitRadius: 7,
				},
			],
		};

		let toolTipLabel = this.props.toolTipLabel;
		let toolTipColour = this.props.toolTipColour;
		let isCurrent = this.props.isCurrent;
		const options = {
			borderColor: 'red',
			aspectRatio: 2.75,
			scales: {
				y: {
					suggestedMax: this.props.yMax,
					suggestedMin: this.props.yMin,
					ticks: {
						color: 'rgb(200, 200, 200)',
					},
					title: {
						display: true,
						text: this.props.ytext,
						color: 'rgb(200, 200, 200)',
					},
					grid: {
						color: 'black',
						drawOnChartArea: false,
					},
					border:{
						color:'rgb(200, 200, 200)'
					}
				},
				x: {
					ticks: {
						color: 'rgb(200, 200, 200)',
					},
					grid: {
						color: 'black',
						drawOnChartArea: false,
					},
					border:{
						color:'rgb(200, 200, 200)'
					}
				},
			},
			plugins: {
				legend: {
					display: true,
					position: 'right' as 'right',
					labels: {
						color: 'rgb(200, 200, 200)',
					},
				},
				tooltip: {
					padding: 10,
					backgroundColor: toolTipColour, //'rgba(25, 25, 25, 0.8)',
					callbacks: {
						title: function (
							this: TooltipModel<'line'>,
							tooltipItems: TooltipItem<'line'>[]
						) {
							let lbl = tooltipItems[0].dataset.label?.toString();
							return tooltipItems[0].parsed.x + 1 + ', ' + lbl;
						},
						label: function (tooltipItem: TooltipItem<'line'>) {
							var label = tooltipItem.dataset.label || '';

							if (label) {
								label =
									numberWithCommas(tooltipItem.parsed.y) + ' ' + toolTipLabel;
							}
							return label;
						},
					},
				},
			},
		};

		return (
			<div>
				<div className="line-chart-title">{this.props.title}</div>

				<Line data={data} options={options} />
			</div>
		);
	}
}

export default LineChart;
