import TopBannerIndex from '../../components/index/TopBannerIndex';
import LoginBlock from './components/LoginBlock';

function LoginPage() {
	return (
		<div className="app">
			<TopBannerIndex />
			<LoginBlock />
		</div>
	);
}

export default LoginPage;
