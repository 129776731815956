import { Plugin } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { numberWithCommas } from '../../../../helperFunctions/helpers';
import { CampaignMonthlyData } from '../../../../models/campaignsListResponse';
import DownArrow from '../../../../assets/images/DownArrow.svg';
import UpArrow from '../../../../assets/images/UpArrow.svg';
import './CardCampaigns.scss';

function CardCampaigns(props: {
	title: string;
	tooltip: string;
	iscampaigns?: boolean;
	isCurve?: boolean;
	mainData: CampaignMonthlyData[];
	subHeader: string;
	url: string;
	dataFunction: (data: CampaignMonthlyData) => number;
	rowCount: number;
}) {
	const labels: String[] = [];
	const chartData: number[] = [];
	let campaignsData = 0;
	let quarterlyChange = 0;
	let totalChanges = 0;

	let zeroCount = 0;
	let wasLastZero = true;
	props.mainData.forEach((x, i) => {
		if (!wasLastZero) {
			return;
		}
		if (x.TotalHits == 0) {
			zeroCount++;
		} else {
			wasLastZero = false;
		}
	});

	const slicedData = props.mainData.slice(
		zeroCount >= 2 ? zeroCount - 2 : zeroCount
	);

	slicedData.forEach((x, i) => {
		labels.push(`${x.MonthName} ${x.Year.toString().substring(2, 4)} `);
		chartData.push(props.dataFunction(x));
		campaignsData += props.dataFunction(x);
		if (i + 1 != slicedData.length) {
			if (props.dataFunction(x) == 0) {
				quarterlyChange += 0;
			} else {
				totalChanges++;
				const percentageChange =
					((props.dataFunction(slicedData[i + 1]) - props.dataFunction(x)) /
						props.dataFunction(x)) *
					100;
				quarterlyChange += percentageChange;
			}
		}
	});

	let percentageChangePerQuarter = quarterlyChange / totalChanges;

	let arrowImage;
	let arrowBackground;
	let headerTextClass = 'header-text ';

	if (percentageChangePerQuarter >= 0) {
		arrowImage = { backgroundImage: `url(${UpArrow})` };
		arrowBackground = { backgroundColor: '#1A9D11' };
		headerTextClass += 'green-header';
	} else {
		percentageChangePerQuarter = percentageChangePerQuarter * -1;
		arrowImage = { backgroundImage: `url(${DownArrow})` };
		arrowBackground = { backgroundColor: '#CC011A' };
		headerTextClass += 'red-header';
	}

	const tooltipLabel = props.tooltip;

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 3.75,
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
			},
			tooltip: {
				padding: 10,
				callbacks: {
					label: function (tooltipItem: any) {
						var label = tooltipItem.dataset.label || '';

						if (label) {
							label =
								numberWithCommas(tooltipItem.parsed.y) + ' ' + tooltipLabel;
						}
						return label;
					},
				},
			},
		},
		scales: {
			y: {
				grid: {
					drawOnChartArea: false,
					color: '#B1B1B1',
				},
				border: {
					color: '#B1B1B1',
				},
			},
			x: {
				grid: {
					drawOnChartArea: false,
					color: '#B1B1B1',
				},
				ticks: {
					maxRotation: 90,
					minRotation: 50,
				},
				border: {
					color: '#B1B1B1',
				},
			},
		},
	};

	const data = {
		labels,
		datasets: [
			{
				lineTension: props.isCurve ? 0.3 : 0,
				data: chartData,
				label: 'Dataset',
				borderColor: 'rgba(23, 93, 208, 0.8)',
				backgroundColor: 'transparent',
				pointRadius: 5,
				borderWidth: 1.5,
				pointHoverRadius: 7,
			},
		],
	};

	const tooltipLine: Plugin = {
		id: 'tooltipLine',
		beforeDraw: (chart) => {
			if (
				typeof chart.tooltip != 'undefined' &&
				chart?.tooltip?.getActiveElements().length >= 1
			) {
				const ctx = chart.ctx;
				ctx.save();
				const activePoint = chart.tooltip.getActiveElements()[0];

				ctx.beginPath();
				ctx.setLineDash([4, 4]);
				ctx.moveTo(activePoint.element.x, activePoint.element.y);
				ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
				ctx.lineWidth = 1.5;
				ctx.strokeStyle = 'rgba(23, 93, 208, 0.5)';
				ctx.stroke();
				ctx.restore();
			}
		},
	};

	const clickHeader = function () {
		if (props.url.includes('https://'))
			window.open(props.url, '_blank')?.focus();
		else window.location.assign(props.url);
	};

	const plugins = [tooltipLine];
	const topheaderClass =
		props.subHeader == '' ? 'top-header' : 'top-header has-sub-heading';

	const contentClass =
		props.rowCount === 1
			? 'campaigns-large-card-content single-row single-row-no-legend'
			: props.rowCount === 3
			? 'campaigns-large-card-content three-row'
			: 'campaigns-large-card-content';
	return (
		<div className="campaigns-large-card">
			<div className="campaigns-large-card-top">
				<div className={topheaderClass} onClick={clickHeader}>
					{props.title}
				</div>
				{props.subHeader != '' ? (
					<div className="top-sub-header">{props.subHeader}</div>
				) : (
					''
				)}
			</div>
			<div className="campaigns-large-card-header">
				<div>
					<div className="campaigns-large-card-header-box">
						<div className="header ">
							<span className="header-text blue-header">
								{campaignsData.toLocaleString()}
							</span>
						</div>
						<span className="header-text-base">
							Past {props.mainData.length} months
						</span>
					</div>
				</div>
				<div>
					<div className="campaigns-large-card-header-box">
						<div className="header">
							<span className={headerTextClass}>
								{Math.round(percentageChangePerQuarter)}%
							</span>
							<span
								style={arrowBackground}
								className="campaigns-large-card-circle-arrow">
								<span
									style={arrowImage}
									className="campaigns-large-card-circle-arrow-image"></span>
							</span>
						</div>
						<span className="header-text-base">Per month</span>
					</div>
				</div>
			</div>

			<div className={contentClass}>
				<div className="campaigns-large-card-chart">
					<Line options={options} data={data} plugins={plugins} />
				</div>
			</div>
		</div>
	);
}

export default CardCampaigns;
