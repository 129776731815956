import React, { useState } from 'react';
import { login } from '../../../environment';
import { LoginResponse } from '../../../models/loginResponse';
import './LoginBlock.scss';

function LoginBlock() {
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleUserNameChange = function (
		e: React.ChangeEvent<HTMLInputElement>
	) {
		setUserName(e.target.value);
	};

	const handlePasswordChange = function (
		e: React.ChangeEvent<HTMLInputElement>
	) {
		setPassword(e.target.value);
	};

	const HandleLogin = function () {
		if (isLoggingIn) {
			return;
		}

		setIsLoggingIn(true);
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ UserName: userName, Password: password }),
		};

		fetch(login, requestOptions)
			.then((response) => response.json())
			.then((data: LoginResponse) => {
				setIsLoggingIn(false);
				sessionStorage.setItem('login-response', JSON.stringify(data));
				if (!data.Success) {
					setErrorMessage('Invalid username or password');
				} else {
					setErrorMessage('');
					window.location.assign('/campaigns');
				}
			});
	};

	return (
		<div className="wrapper">
			<div className="centered-wrapper">
				<div className="login-card">
					<div className="login-card-title">Login</div>
					<div
						style={{ visibility: errorMessage !== '' ? 'visible' : 'hidden' }}>
						<br />
						<span style={{ color: 'red' }}>{errorMessage}</span>
					</div>
					<br />
					<form>
						<label>Username</label>
						<br />
						<input
							type="text"
							name="name"
							className="username"
							value={userName}
							onChange={handleUserNameChange}
						/>
						<br />
						<br />
						<label>Password</label>
						<br />
						<input
							type="password"
							className="password"
							name="name"
							value={password}
							onChange={handlePasswordChange}
						/>
						<br />
						<br />
						<br />
						<div className="submit" onClick={HandleLogin}>
							Login
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default LoginBlock;
