import React from 'react';
import { useState } from 'react';
import CoveLogoFullWidth from '../../../components/CoveLogoFullWidth/CoveLogoFullWidth';
import Loading from '../../../components/Loading';
import TopBannerWithoutSwiper from '../../../components/TopBanner/TopBannerWithoutSwiper';
import { getCampaign } from '../../../environment';
import { LoginResponse } from '../../../models/loginResponse';
import './CampaignPage.scss';
import CardCampaigns from '../components/CardCampaigns/CardCampaigns';
import {
	ArticleData,
	ArticleMonthlyData,
	ArticleMonthlyData2,
	CampaignResponse,
	TotalData,
} from '../../../models/campaignResponse';
import CardCampaignsMultiLine from '../components/CardCampaigns/CardCampaignsMultiLine';

function CampaignPage() {
	const [IsLoading, setIsLoading] = useState(false);
	const [campaigns, setCampaigns] = useState<ArticleData[]>([]);
	const [campaign, setCampaign] = useState<TotalData>({
		Name: '',
		ArticleMonthlyData: [],
	});

	const [title, setTitle] = useState('');
	// const loginResponse: LoginResponse = JSON.parse(
	// 	sessionStorage.getItem('login-response') ?? '{}'
	// );

	// No login required
	// if (!loginResponse.Success) {
	// 	window.location.assign('login');
	// }

	React.useEffect(() => {
		const date = window.location.pathname.split('/');

		setIsLoading(true);
		fetch(`${getCampaign}identifier=${date[2]}`)
			.then((res) => res.json())
			.then((result: CampaignResponse) => {
				setIsLoading(false);
				setCampaigns(
					result.ArticleData.sort(
						(b, a) =>
							a.ArticleMonthlyData.reduce(
								(sum, current) => sum + current.TotalHits,
								0
							) -
							b.ArticleMonthlyData.reduce(
								(sum, current) => sum + current.TotalHits,
								0
							)
					)
				);
				setCampaign(result.TotalData);
				setTitle(result.Name);
			});
	}, [window.location]);

	if (IsLoading) {
		return <Loading Background="#F5F5F5" />;
	} else {
		return (
			<div style={{ overflowY: 'scroll' }}>
				<TopBannerWithoutSwiper
					Url="/campaigns"
					Title={title}
					SubTitle="All time data"
				/>

				<div className="campaign-wrapper">
					<CoveLogoFullWidth />
					<div className="campaign-container-single">
						<CardCampaigns
							rowCount={1}
							dataFunction={(monthlyData: ArticleMonthlyData2) =>
								monthlyData.TotalHits
							}
							url=""
							title="ARTICLE HITS (ALL TIME)"
							tooltip="views"
							isCurve={true}
							subHeader=""
							mainData={campaign.ArticleMonthlyData}
						/>
					</div>

					{campaigns.length <= 15 && campaigns.length > 1 ? (
						<div className="campaign-container-single">
							<CardCampaignsMultiLine
								dataFunction={(monthlyData: ArticleMonthlyData) =>
									monthlyData.TotalHits
								}
								labels={campaigns.map((x) => x.Name)}
								mainData={campaigns.map((x) => x.ArticleMonthlyData)}
								title={`${title} Statistics`}
								tooltip="views"
								isCurve={true}
							/>
						</div>
					) : (
						<span />
					)}
					<div className="campaign-container">
						{campaigns.map((campaign) => {
							return (
								<CardCampaigns
									key={campaign.Name}
									rowCount={3}
									dataFunction={(monthlyData: ArticleMonthlyData) =>
										monthlyData.TotalHits
									}
									url={campaign.Url}
									title={campaign.Name}
									tooltip="views"
									isCurve={true}
									subHeader=""
									mainData={campaign.ArticleMonthlyData}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default CampaignPage;
