import TheCoveLogo from '../../assets/images/TheCove-Logo.png';
import CovePlusLogo from '../../assets/images/CovePlus-Logo.png';
import '../../styles/TopBanner.css';
import { Link } from 'react-router-dom';
import LeftArrow from '../../assets/images/LeftArrow.svg';
import RightArrow from '../../assets/images/RightArrow.svg';
import {
	goToNextMonth,
	goToPreviousMonth,
} from '../../helperFunctions/navhelper';
import { useGlobalState } from '../../state/state';
import { getMonthName } from '../../helperFunctions/helpers';
import { useNavigate } from 'react-router-dom';
import { PageProps } from '../../models/pageProps';

function TopBanner(props: PageProps) {
	const navigate = useNavigate();
	const [mouseMoving] = useGlobalState('mouseMoving');

	return (
		<div style={{ backgroundColor: 'white', position: 'relative' }}>
			<div className="top-banner">
				<div className="centre-top">
					<Link to="/">
						<span>The Cove Analytics</span>
					</Link>
					<br />
					{mouseMoving && (
						<img
							className="month-nav-arrows"
							src={LeftArrow}
							onClick={PreviousMonth}
							alt="left-arrow"></img>
					)}
					<Link to="/">
						<span className="centre-top-month center-top-month-large">
							{`${getMonthName(props.Month)} ${props.Year} ${
								props.IsCurrent ? '(in progress)' : ''
							}`}
						</span>
						<span className="centre-top-month center-top-month-small">
							{`${getMonthName(props.Month, true)} ${props.Year} ${
								props.IsCurrent ? '(in progress)' : ''
							}`}
						</span>
					</Link>
					{mouseMoving && (
						<img
							className="month-nav-arrows"
							src={RightArrow}
							onClick={NextMonth}
							alt="right-arrow"></img>
					)}
				</div>
			</div>

			<div className="top-banner-split-view">
				<div className="the-cove-logo">
					<a href="https://cove.army.gov.au/">
						<img src={TheCoveLogo} alt="The Cove Logo"></img>
					</a>
				</div>
				<div className="cove-plus-logo">
					<a href="https://cove.army.gov.au/article/cove-armys-professional-development-program">
						<img src={CovePlusLogo} alt="Cove Plus Logo"></img>
					</a>
				</div>
			</div>
		</div>
	);

	async function PreviousMonth() {
		await goToPreviousMonth(props.Year, props.Month, navigate);
	}

	async function NextMonth() {
		await goToNextMonth(props.Year, props.Month, navigate);
	}
}

export default TopBanner;
