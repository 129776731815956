import { getPercentageChange } from '../helperFunctions/helpers'
import { CovePlusResponse } from '../models/covePlusResponse';
import { TopListItem } from './Interfaces';

interface TopList extends Array<TopListItem> { }

function MonthNumber(data: CovePlusResponse): number {
    if (data.MonthlyData[0].Year > data.MonthlyData[1].Year) {
        return data.MonthlyData[0].Month;
    } else if (data.MonthlyData[0].Year < data.MonthlyData[1].Year) {
        return data.MonthlyData[1].Month
    } else {
        return data.MonthlyData[0].Month > data.MonthlyData[1].Month ? data.MonthlyData[0].Month : data.MonthlyData[1].Month;
    }
}

function getPreviousMonth(month: number): number {
    if (month === 1) {
        return 12;
    } else {
        return month - 1;
    }
}

export function getTotalUnitCompleted(data: CovePlusResponse): number {
    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) { // needs +1 because this starts from zero
            e.UnitCompletions.forEach(function (e) {
                total += e.Data;
            });
        }
    })
    return total
}

export function getUnitCompletedPercentIncrease(data: CovePlusResponse): number {
    let prevTotal: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) { // previous month so does not need +1
            e.UnitCompletions.forEach(function (e) {
                prevTotal += e.Data;
            });
        }
    })

    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) {  // current month needs +1 because this starts from zero
            e.UnitCompletions.forEach(function (e) {
                total += e.Data;
            });
        }
    })

    UnitCompletedIsUp = prevTotal < total;
    return (getPercentageChange(prevTotal, total));
}

export function getTotalUnitEnroll(data: CovePlusResponse): number {
    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) {
            e.UnitEnrolments.forEach(function (e) {
                total += e.Data;
            });
        }
    })
    return total
}

export function getUnitEnrollPercentIncrease(data: CovePlusResponse): number {
    let prevTotal: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) {
            e.UnitEnrolments.forEach(function (e) {
                prevTotal += e.Data;
            });
        }
    })

    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) {
            e.UnitEnrolments.forEach(function (e) {
                total += e.Data;
            });
        }
    })

    UnitEnrollIsUp = prevTotal < total;
    return (getPercentageChange(prevTotal, total));
}

export function getTotalProgramEnroll(data: CovePlusResponse): number {
    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) {
            e.ProgramEnrolments.forEach(function (e) {
                total += e.Data;
            });
        }
    })
    return total
}

export function getProgramEnrollPercentIncrease(data: CovePlusResponse): number {
    let prevTotal: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) {
            e.ProgramEnrolments.forEach(function (e) {
                prevTotal += e.Data;
            });
        }
    })

    let total: number = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) {
            e.ProgramEnrolments.forEach(function (e) {
                total += e.Data;
            });
        }
    })
    ProgramEnrollIsUp = prevTotal < total;
    return (getPercentageChange(prevTotal, total));
}

export function getUnitEnrolledDataThisMonth(data: CovePlusResponse): Array<number> {
    let unitEnrolls: Array<number> = [];
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) {
            e.UnitEnrolments.forEach(function (e) {
                unitEnrolls.push(e.Data);
            })
        }
    })
    return unitEnrolls
}

export function getUnitEnrolledDataLastMonth(data: CovePlusResponse): Array<number> {
    let unitEnrolls: Array<number> = [];
    data.MonthlyData.forEach(function (e) {
        if (e.Month === getPreviousMonth(MonthNumber(data))) {
            e.UnitEnrolments.forEach(function (e) {
                unitEnrolls.push(e.Data);
            })
        }
    })
    return unitEnrolls
}

export function getTopUnitsList(data: CovePlusResponse): TopList {
    let articleList: TopList = [];
    let idCount = 0;
    data.MonthlyData.forEach(function (e) {
        if (e.Month === MonthNumber(data)) {
            e.PopularUnits.forEach(function (e) {
                articleList.push(
                    {
                        id: idCount,
                        start: e.Category,
                        end: e.Enrolments,
                        text: e.ModuleName,
                        img: e.Image,
                        link: "", // No link available for moodle unit modules
                    }
                );
                idCount++
            })
        }
    })
    return articleList
}

export var UnitCompletedIsUp: boolean;
export var UnitEnrollIsUp: boolean;
export var ProgramEnrollIsUp: boolean;