export function numberWithCommas(x: number) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function splitToChunks(array: number[], parts: number) {
	let result: number[][] = [];
	for (let i = parts; i > 0; i--) {
		result.push(array.splice(0, Math.ceil(array.length / i)));
	}
	let newResult: number[] = [0, 0, 0, 0];
	for (var i = 0; i < result.length; i++) {
		for (var j = 0; j < result[i].length; j++) {
			newResult[i] += result[i][j];
		}
	}
	return newResult;
}

export function getPercentageChange(oldNumber: number, curNumber: number) {
	var decreaseValue = oldNumber - curNumber;
	return (decreaseValue / oldNumber) * 100;
}

const month = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const lineColors = [
	'rgba(23, 93, 208, 0.8)',
	'rgba(26,157,17, 0.8)',
	'rgba(204,1,26, 0.8)',
	'rgba(54, 162, 235,0.8)',
	'rgba(255, 205, 86,0.8)',
	'rgba(255, 159, 64,0.8)',
	'rgba(100, 100, 255,1)',
	'rgba(153, 102, 255,0.8)',
	'rgba(255, 99, 132,0.8)',
	'rgba(75, 192, 192,0.8)',
	'lightcoral',
	'pink',
	'lightsalmon',
	'lightseagreen',
	'#691a71',
];

const monthShort = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sept',
	'Oct',
	'Nov',
	'Dec',
];

export function getMonthName(monthNumber: number, isShort = false): string {
	if (isShort) return monthShort[monthNumber - 1];
	return month[monthNumber - 1];
}
