import { Plugin } from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
	numberWithCommas,
	lineColors,
} from '../../../../helperFunctions/helpers';
import { CampaignMonthlyData } from '../../../../models/campaignsListResponse';
import './CardCampaigns.scss';

function CardCampaignsMultiLine(props: {
	title: string;
	tooltip: string;
	isCurve?: boolean;
	mainData: CampaignMonthlyData[][];
	labels: string[];
	dataFunction: (data: CampaignMonthlyData) => number;
}) {
	const labels: String[] = [];
	let minZero = 500;
	if (props.mainData.length > 1) {
		props.mainData.forEach((x, i) => {
			let zeroCount = 0;
			props.mainData[i].forEach((x) => {
				if (x.TotalHits === 0) {
					zeroCount++;
				}
			});

			minZero = zeroCount < minZero ? zeroCount : minZero;
		});

		props.mainData[0]
			.slice(minZero >= 2 ? minZero - 2 : minZero)
			.forEach((x, i) => {
				labels.push(`${x.MonthName} ${x.Year}`);
			});
	}

	const getOrCreateLegendList = (chart: any, id: any) => {
		const legendContainer = document.getElementById(id);
		let listContainer = legendContainer?.querySelector('ul');

		if (!listContainer) {
			listContainer = document.createElement('ul');
			listContainer.classList.add('legend-list');

			legendContainer?.appendChild(listContainer);
		}

		return listContainer;
	};

	const htmlLegendPlugin: Plugin = {
		id: 'htmlLegend',
		afterUpdate(chart, args, options) {
			const ul = getOrCreateLegendList(chart, options.containerID);

			// Remove old legend items
			while (ul.firstChild) {
				ul.firstChild.remove();
			}
			const legendLabels = chart?.options?.plugins?.legend?.labels;
			const items =
				typeof legendLabels == 'undefined'
					? []
					: legendLabels.generateLabels?.(chart) || [];

			items.forEach((item) => {
				const li = document.createElement('li');
				li.classList.add('legend-item');

				li.onclick = () => {
					chart.setDatasetVisibility(
						item.datasetIndex!,
						!chart.isDatasetVisible(item.datasetIndex!)
					);
					chart.update();
				};

				// Color box
				const boxSpan = document.createElement('span');
				boxSpan.classList.add('legend-color-box');
				const displayColor = item.strokeStyle?.toString() || 'transparent';
				boxSpan.style.background = item.hidden ? 'transparent' : displayColor;
				boxSpan.style.borderWidth = item.lineWidth + 'px';
				boxSpan.style.borderColor = displayColor;

				// Text
				const textContainer = document.createElement('p');
				textContainer.style.color = item.fontColor?.toString() || 'transparent';
				textContainer.style.margin = '0';
				textContainer.style.padding = '0';
				textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

				const text = document.createTextNode(item.text);
				textContainer.appendChild(text);

				li.appendChild(boxSpan);
				li.appendChild(textContainer);
				ul.appendChild(li);
			});
		},
	};

	const options: any = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 3.75,
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
				labels: {
					font: {
						size: '15',
					},
					pointStyle: 'rect',
					usePointStyle: true,
				},
			},
			htmlLegend: {
				containerID: 'legend-container',
			},
			tooltip: {
				padding: 10,
				callbacks: {
					label: function (tooltipItem: any) {
						var label = tooltipItem.dataset.label || '';

						if (label) {
							const tooltipLabel = 'views';
							label =
								numberWithCommas(tooltipItem.parsed.y) + ' ' + tooltipLabel;
						}
						return label;
					},
				},
			},
		},
		scales: {
			y: {
				grid: {
					drawOnChartArea: false,
					color: '#B1B1B1',
				},
				border: {
					color: '#B1B1B1',
				},
			},
			x: {
				grid: {
					drawOnChartArea: false,
					color: '#B1B1B1',
				},
				ticks: {
					maxRotation: 90,
					minRotation: 50,
				},
				border: {
					color: '#B1B1B1',
				},
			},
		},
	};

	const datasets = [];

	const dataToIterate = props.mainData.sort(
		(b, a) =>
			a.reduce((sum, current) => sum + current.TotalHits, 0) -
			b.reduce((sum, current) => sum + current.TotalHits, 0)
	);

	for (var i = 0; i < dataToIterate.length; i++) {
		const chartData1: any = [];
		dataToIterate[i]
			.slice(minZero >= 2 ? minZero - 2 : minZero)
			.forEach((x, i) => {
				chartData1.push(props.dataFunction(x));
			});

		datasets.push({
			lineTension: props.isCurve ? 0.3 : 0,
			data: chartData1,
			label: props.labels[i],
			borderColor: lineColors[i],
			backgroundColor: 'transparent',
			pointRadius: 5,
			borderWidth: 1.5,
			pointHoverRadius: 7,
			hidden: i > 4,
		});
	}

	const data = {
		labels,
		datasets: datasets,
	};

	const tooltipLine: Plugin = {
		id: 'tooltipLine',
		beforeDraw: (chart) => {
			if (
				typeof chart.tooltip != 'undefined' &&
				chart?.tooltip?.getActiveElements().length >= 1
			) {
				const ctx = chart.ctx;
				ctx.save();
				const activePoint = chart.tooltip.getActiveElements()[0];
				ctx.beginPath();
				ctx.setLineDash([4, 4]);
				ctx.moveTo(activePoint.element.x, activePoint.element.y);
				ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
				ctx.lineWidth = 1.5;
				// ctx.strokeStyle = 'rgba(23, 93, 208, 0.5)';
				ctx.strokeStyle = chart.tooltip.labelColors[0].borderColor
					.toString()
					.replace('0.8', '0.5');
				ctx.stroke();
				ctx.restore();
			}
		},
	};

	const plugins = [tooltipLine, htmlLegendPlugin];
	return (
		<div className="campaigns-large-card">
			<div className="campaigns-large-card-top">
				<div className="top-header">{props.title}</div>
			</div>
			<div className="campaigns-large-card-content  single-row">
				<div className="campaigns-large-card-chart">
					<Line options={options} data={data} plugins={plugins} />
				</div>
				<div id="legend-container"></div>
			</div>
		</div>
	);
}

export default CardCampaignsMultiLine;
