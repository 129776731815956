import LineChart from './LineChart';
import '../../styles/CovePlus.css';
import LargeCard from './cards/LargeCard';
import SmallCard from './cards/SmallCard';
import TopListCardUnits from './cards/TopListCardUnits';
import { numberWithCommas, getMonthName } from '../../helperFunctions/helpers';
import { TopListItem } from '../../dataService/Interfaces';
import {
	getTotalUnitCompleted,
	getTotalProgramEnroll,
	getTotalUnitEnroll,
	getUnitCompletedPercentIncrease,
	getProgramEnrollPercentIncrease,
	getUnitEnrollPercentIncrease,
	getUnitEnrolledDataThisMonth,
	getUnitEnrolledDataLastMonth,
	UnitCompletedIsUp,
	ProgramEnrollIsUp,
	UnitEnrollIsUp,
	getTopUnitsList,
} from '../../dataService/GetCovePlusData';
import React, { useState } from 'react';
import { getCovePlusDataUrl } from '../../environment';
import { CovePlusResponse } from '../../models/covePlusResponse';
import { PageProps } from '../../models/pageProps';
import Loading from '../Loading';
import NoData from '../NoData';

function CovePlus(props: PageProps) {
	const [totalUnitsCompleted, settotalUnitsCompleted] = useState(0);
	const [totalProgramEnroll, settotalProgramEnroll] = useState(0);
	const [totalUnitEnroll, settotalUnitEnroll] = useState(0);
	const [unitsCompPercent, setunitsCompPercent] = useState(0);
	const [programEnrollPercent, setprogramEnrollPercent] = useState(0);
	const [unitEnrollPercent, setunitEnrollPercent] = useState(0);

	const [unitsCompIsUp, setunitsCompIsUp] = useState(false);
	const [programEnrollIsUp, setprogramEnrollIsUp] = useState(false);
	const [unitEnrollIsUp, setunitEnrollIsUp] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [hasData, setHasData] = useState(true);

	interface TopList extends Array<TopListItem> {}

	const [TopArticles, setTopArticles] = useState<TopList>([]);

	const [data1, setData1] = useState<number[]>([]);
	const [data2, setData2] = useState<number[]>([]);

	const [stroke, setStroke] = React.useState(553);
	const [strokeSmall, setStrokeSmall] = React.useState(227);

	React.useEffect(() => {
		setIsLoading(true);
		fetch(`${getCovePlusDataUrl}&month=${props.Month}&year=${props.Year}`)
			.then((res) => res.json())
			.then((result: CovePlusResponse) => {
				if (result.MonthlyData.length <= 1) {
					setHasData(false);
					setIsLoading(false);
					return;
				}
				setHasData(true);

				settotalUnitsCompleted(getTotalUnitCompleted(result));
				settotalProgramEnroll(getTotalProgramEnroll(result));
				settotalUnitEnroll(getTotalUnitEnroll(result));
				setunitsCompPercent(getUnitCompletedPercentIncrease(result));
				setprogramEnrollPercent(getProgramEnrollPercentIncrease(result));
				setunitEnrollPercent(getUnitEnrollPercentIncrease(result));

				setunitsCompIsUp(UnitCompletedIsUp);
				setprogramEnrollIsUp(ProgramEnrollIsUp);
				setunitEnrollIsUp(UnitEnrollIsUp);

				setTopArticles(getTopUnitsList(result));

				setData1(getUnitEnrolledDataThisMonth(result));
				setData2(getUnitEnrolledDataLastMonth(result));

				setIsLoading(false);
				const dateinMonth = new Date(props.Year, props.Month, 0).getDate();
				setStroke(
					(1 - getUnitEnrolledDataThisMonth(result).length / dateinMonth) * 553
				);
				setStrokeSmall(
					(1 - getUnitEnrolledDataThisMonth(result).length / dateinMonth) * 227
				);
			});
	}, [props.Month]);

	if (isLoading) return <Loading Background="rgba(0, 0, 0, 0.05)" />;
	if (!hasData) return <NoData Background="rgba(0, 0, 0, 0.05)" />;

	return (
		<div>
			<div className="unit-enrols-chart-background">
				<div className="container">
					<LineChart
						title="Cove+ Unit Enrolments"
						ytext="Unit enrolment"
						data1={data1}
						data2={data2}
						label1={getMonthName(props.Month).substring(0, 3)}
						label2={getMonthName(getPreviousMonth(props.Month)).substring(0, 3)}
						yMax={120}
						yMin={0}
						toolTipLabel="enrols"
						toolTipColour="rgba(30, 30, 30, 0.8)"
						isCurrent={props.IsCurrent}
					/>
				</div>
			</div>
			<div className="centre-flex-container">
				<div className="left-large-card">
					<LargeCard
						isCurrent={props.IsCurrent}
						month={props.Month}
						stroke={props.IsCurrent ? stroke : 553}
						title="UNIT ENROLMENTS"
						stat={unitEnrollPercent}
						totalDaysInCurrentMonth={data1.length}
						total={numberWithCommas(totalUnitEnroll)}
						isUp={unitEnrollIsUp}
						enrolHitText={'enrolments'}
					/>
				</div>
				<div className="right-four-cards">
					<SmallCard
						isCurrent={props.IsCurrent}
						title="PROGRAM ENROLMENTS"
						stat={programEnrollPercent}
						total={numberWithCommas(totalProgramEnroll)}
						isUp={programEnrollIsUp}
						isWide={true}
						totalDaysInCurrentMonth={data1.length}
						month={props.Month}
						stroke={props.IsCurrent ? strokeSmall : 227}
					/>
					<SmallCard
						isCurrent={props.IsCurrent}
						title="UNITS COMPLETED"
						stat={unitsCompPercent}
						total={numberWithCommas(totalUnitsCompleted)}
						isUp={unitsCompIsUp}
						isWide={true}
						totalDaysInCurrentMonth={data1.length}
						month={props.Month}
						stroke={props.IsCurrent ? strokeSmall : 227}
					/>
				</div>
			</div>
			<div className="centre-flex-container">
				<TopListCardUnits
					title="MOST POPULAR UNITS"
					smallSpace={true}
					data={TopArticles}
					useLinks={false}
				/>
			</div>
		</div>
	);

	function getPreviousMonth(month: number): number {
		if (month === 1) {
			return 12;
		} else {
			return month - 1;
		}
	}
}

export default CovePlus;
