import { Plugin } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { numberWithCommas } from '../../../helperFunctions/helpers';
import { QuarterlyData } from '../../../models/allTimeDataResponse';
import './LargeCardAllTime.scss';

function LargeCardMultiLineAllTime(props: {
	title: string;
	tooltip: string;
	isAllTime?: boolean;
	isCurve?: boolean;
	mainData: QuarterlyData[];
	dataFunction1: (data: QuarterlyData) => number;
	dataFunction2: (data: QuarterlyData) => number;
	dataFunction3: (data: QuarterlyData) => number;
}) {
	const labels: String[] = [];
	const chartData1: number[] = [];
	const chartData2: number[] = [];
	const chartData3: number[] = [];

	props.mainData.forEach((x, i) => {
		labels.push(`${x.Year} Q${x.Quarter}`);
		chartData1.push(props.dataFunction1(x));
		chartData2.push(props.dataFunction2(x));
		chartData3.push(props.dataFunction3(x));
	});

	const options: any = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 3.75,
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: true,
				labels: {
					font: {
						size: '15',
					},
					pointStyle: 'line',
					usePointStyle: true,
				},
			},
			tooltip: {
				padding: 10,
				callbacks: {
					label: function (tooltipItem: any) {
						var label = tooltipItem.dataset.label || '';

						if (label) {
							const tooltipLabel =
								tooltipItem.dataset.label == 'Unit Enrolments' ||
								tooltipItem.dataset.label == 'Program Enrolments'
									? 'enrollments'
									: 'units';
							label =
								numberWithCommas(tooltipItem.parsed.y) + ' ' + tooltipLabel;
						}
						return label;
					},
				},
			},
		},
		scales: {
			y: {
				grid: {
					drawOnChartArea: false,
					color: '#B1B1B1',
				},
				border: {
					color: '#B1B1B1',
				},
			},
			x: {
				grid: {
					drawOnChartArea: false,
					color: '#B1B1B1',
				},
				border: {
					color: '#B1B1B1',
				},
				ticks: {
					// callback: function (value: any, index: number, ticks: any) {
					// 	const currentlabel = labels[value];
					// 	const year = currentlabel.split(' ')[0];
					// 	if (value == 0) {
					// 		return currentlabel.toString();
					// 	}
					// 	const lastYear = labels[value - 1].split(' ')[0];
					// 	if (lastYear != year) {
					// 		return currentlabel.toString();
					// 	}

					// 	return '';
					// },
					maxRotation: 90,
					minRotation: 50,
				},
			},
		},
	};

	const data = {
		labels,
		datasets: [
			{
				lineTension: props.isCurve ? 0.3 : 0,
				data: chartData1,
				label: 'Program Enrolments',
				borderColor: 'rgba(23, 93, 208, 0.8)',
				backgroundColor: 'transparent',
				pointRadius: 5,
				borderWidth: 1.5,
				pointHoverRadius: 7,
			},
			{
				lineTension: props.isCurve ? 0.3 : 0,
				data: chartData2,
				label: 'Unit Enrolments',
				borderColor: 'rgba(26,157,17, 0.8)',
				backgroundColor: 'transparent',
				pointRadius: 5,
				borderWidth: 1.5,
				pointHoverRadius: 7,
			},
			{
				lineTension: props.isCurve ? 0.3 : 0,
				data: chartData3,
				label: 'Unit Completions',
				borderColor: 'rgba(204,1,26, 0.8)',
				backgroundColor: 'transparent',
				pointRadius: 5,
				borderWidth: 1.5,
				pointHoverRadius: 7,
			},
		],
	};

	const tooltipLine: Plugin = {
		id: 'tooltipLine',
		beforeDraw: (chart) => {
			if (
				typeof chart.tooltip != 'undefined' &&
				chart?.tooltip?.getActiveElements().length >= 1
			) {
				const ctx = chart.ctx;
				ctx.save();
				const activePoint = chart.tooltip.getActiveElements()[0];
				console.log(chart.tooltip.labelColors);
				ctx.beginPath();
				ctx.setLineDash([4, 4]);
				ctx.moveTo(activePoint.element.x, activePoint.element.y);
				ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
				ctx.lineWidth = 1.5;
				// ctx.strokeStyle = 'rgba(23, 93, 208, 0.5)';
				ctx.strokeStyle = chart.tooltip.labelColors[0].borderColor
					.toString()
					.replace('0.8', '0.5');
				ctx.stroke();
				ctx.restore();
			}
		},
	};

	const plugins = [tooltipLine];
	return (
		<div className="alltime-large-card">
			<div className="alltime-large-card-top">
				<div className="top-header">{props.title}</div>
			</div>
			<div className="alltime-large-card-content">
				<Line options={options} data={data} plugins={plugins} />
			</div>
		</div>
	);
}

export default LargeCardMultiLineAllTime;
