import { NavigateFunction } from 'react-router-dom';
import { TOCResponse } from './../models/tocResponse';
export async function goToPreviousMonth(year: number, month: number, navigate: NavigateFunction) {
  const toc = localStorage.getItem("toc") ?? "{}";
  const tocResponse: TOCResponse = JSON.parse(toc);

  const toNavigateMonth = month === 1 ? 12 : month - 1;
  const toNavigateYear = month === 1 ? year - 1 : year;

  const result = tocResponse.Years.filter(x => x.Year === toNavigateYear && x.Months.filter(y => y.IsEnabled && y.Month === toNavigateMonth).length >= 1)
  if (result.length >= 1) {
    navigate('/' + toNavigateYear + '/' + toNavigateMonth);
  }
}

export async function goToNextMonth(year: number, month: number, navigate: NavigateFunction) {
  const toc = localStorage.getItem("toc") ?? "{}";
  const tocResponse: TOCResponse = JSON.parse(toc);

  const toNavigateMonth = month === 12 ? 1 : month + 1;
  const toNavigateYear = month === 12 ? year + 1 : year;

  const currentDate= new Date();
  const result = tocResponse.Years.filter(x => x.Year === toNavigateYear && x.Months.filter(y => (y.IsEnabled || (x.Year== currentDate.getFullYear() && y.Month== currentDate.getMonth()+1)) && y.Month === toNavigateMonth).length >= 1)
  if (result.length >= 1) {
    navigate('/' + toNavigateYear + '/' + toNavigateMonth);
  }
}
