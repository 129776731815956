import * as React from 'react';
import { getTOCUrl } from '../../environment';
import { TOCResponse } from '../../models/tocResponse';
import '../../styles/IndexTable.css';
import Loading from '../Loading';

interface IndexTableState {
	TOC: TOCResponse;
	IsLoading: boolean;
}

class IndexTable extends React.Component<any, IndexTableState> {
	constructor(props: any) {
		super(props);

		this.state = {
			TOC: {
				Years: [],
			},
			IsLoading: false,
		};
	}

	componentDidMount() {
		this.setState({
			TOC: { Years: [] },
			IsLoading: true,
		});

		fetch(getTOCUrl)
			.then((res) => res.json())
			.then((result: TOCResponse) => {
				this.setState({ TOC: result, IsLoading: false });
				localStorage.setItem('toc', JSON.stringify(result));

				const initAccordians = () => {
					const accordians = document.getElementsByClassName('accordion');
					//Retry until accordians are loaded
					if (accordians.length == 0) setTimeout(() => initAccordians(), 100);
					for (var i = 0; i < accordians.length; i++) {
						var panel = (accordians[i] as Element)
							.nextElementSibling as HTMLElement;
						if (accordians[i].classList.contains('active'))
							panel.style.maxHeight = panel.scrollHeight + 'px';
						accordians[i].addEventListener('click', (e) => {
							(e.currentTarget as Element).classList.toggle('active');
							var panel = (e.currentTarget as Element)
								.nextElementSibling as HTMLElement;
							if (panel) {
								if (panel.style.maxHeight) {
									panel.style.maxHeight = '';
								} else {
									panel.style.maxHeight = panel.scrollHeight + 'px';
								}
							}
						});
					}
				};

				initAccordians();
			});
	}

	render() {
		if (this.state.IsLoading) {
			return <Loading Background="#F5F5F5" />;
		}
		return (
			<div className="table-contents">
				{/* All Time */}
				<div className="year-block">
					<div
						className="year-title-block alltime-block"
						onClick={() => window.location.assign(`/alltime/`)}>
						All Time
					</div>
				</div>
				{/* Campaigns */}
				<div className="year-block">
					<div
						className="year-title-block alltime-block"
						onClick={() => window.location.assign(`/campaigns/`)}>
						Campaigns
					</div>
				</div>

				{this.state.TOC.Years.sort((a, b) => b.Year - a.Year).map((year) => {
					const activeClass =
						year.Year == new Date().getFullYear()
							? 'year-title-block accordion active'
							: 'year-title-block accordion';
					return (
						<div className="year-block" key={year.Year}>
							<div className={activeClass}>{year.Year}</div>
							<div className="panel">
								{year.Months.filter((x) => !x.IsEnabled)
									.sort((a, b) => b.Month - a.Month)
									.map((month) => {
										return (
											<div
												className="month-block"
												key={`${year.Year}-${month.Month}}`}
												onClick={() =>
													window.location.assign(
														`/${year.Year}/${month.Month}/`
													)
												}>
												{month.MonthName} (in progress)
											</div>
										);
									})}
								{year.Months.filter((x) => x.IsEnabled)
									.sort((a, b) => b.Month - a.Month)
									.map((month) => {
										return (
											<div
												className="month-block"
												key={`${year.Year}-${month.Month}}`}
												onClick={() =>
													window.location.assign(
														`/${year.Year}/${month.Month}/`
													)
												}>
												{month.MonthName}
											</div>
										);
									})}
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

export default IndexTable;
