import { Plugin } from 'chart.js';
import { Line } from 'react-chartjs-2';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import { numberWithCommas } from '../../../helperFunctions/helpers';
import { QuarterlyData } from '../../../models/allTimeDataResponse';
import './LargeCardAllTime.scss';

function LargeCardAllTime(props: {
	title: string;
	tooltip: string;
	isAllTime?: boolean;
	isCurve?: boolean;
	mainData: QuarterlyData[];
	subHeader: string;
	dataFunction: (data: QuarterlyData) => number;
}) {
	const labels: String[] = [];
	const chartData: number[] = [];
	let allTimeData = 0;
	let quarterlyChange = 0;
	let totalChanges = 0;
	props.mainData.forEach((x, i) => {
		labels.push(`${x.Year} Q${x.Quarter}`);
		chartData.push(props.dataFunction(x));
		allTimeData += props.dataFunction(x);
		if (i + 1 !== props.mainData.length) {
			if (props.dataFunction(x) == 0) {
				quarterlyChange += 0;
			} else {
				totalChanges++;
				quarterlyChange +=
					((props.dataFunction(props.mainData[i + 1]) - props.dataFunction(x)) /
						props.dataFunction(x)) *
					100;
			}
		}
	});
	let percentageChangePerQuarter = quarterlyChange / totalChanges;

	let arrowImage;
	let arrowBackground;
	let headerTextClass = 'header-text ';

	if (percentageChangePerQuarter >= 0) {
		arrowImage = { backgroundImage: `url(${UpArrow})` };
		arrowBackground = { backgroundColor: '#1A9D11' };
		headerTextClass += 'green-header';
	} else {
		percentageChangePerQuarter = percentageChangePerQuarter * -1;
		arrowImage = { backgroundImage: `url(${DownArrow})` };
		arrowBackground = { backgroundColor: '#CC011A' };
		headerTextClass += 'red-header';
	}

	const tooltipLabel = props.tooltip;

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 3.75,
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
			},
			tooltip: {
				padding: 10,
				callbacks: {
					label: function (tooltipItem: any) {
						var label = tooltipItem.dataset.label || '';

						if (label) {
							label =
								numberWithCommas(tooltipItem.parsed.y) + ' ' + tooltipLabel;
						}
						return label;
					},
				},
			},
		},
		scales: {
			y: {
				grid: {
					drawOnChartArea: false,
					color: '#B1B1B1',
				},
				border: {
					color: '#B1B1B1',
				},
			},
			x: {
				grid: {
					drawOnChartArea: false,
					color: '#B1B1B1',
				},
				border: {
					color: '#B1B1B1',
				},
				ticks: {
					// callback: function (value: any, index: number, ticks: any) {
					// 	const currentlabel = labels[value];
					// 	const year = currentlabel.split(' ')[0];
					// 	if (value == 0) {
					// 		return currentlabel.toString();
					// 	}
					// 	const lastYear = labels[value - 1].split(' ')[0];
					// 	if (lastYear != year) {
					// 		return currentlabel.toString();
					// 	}

					// 	return '';
					// },
					maxRotation: 90,
					minRotation: 50,
				},
			},
		},
	};

	const data = {
		labels,
		datasets: [
			{
				lineTension: props.isCurve ? 0.3 : 0,
				data: chartData,
				label: 'Dataset',
				borderColor: 'rgba(23, 93, 208, 0.8)',
				backgroundColor: 'transparent',
				pointRadius: 5,
				borderWidth: 1.5,
				pointHoverRadius: 7,
			},
		],
	};

	const tooltipLine: Plugin = {
		id: 'tooltipLine',
		beforeDraw: (chart) => {
			if (
				typeof chart.tooltip !== 'undefined' &&
				chart?.tooltip?.getActiveElements().length >= 1
			) {
				const ctx = chart.ctx;
				ctx.save();
				const activePoint = chart.tooltip.getActiveElements()[0];

				// ctx.beginPath();
				// ctx.setLineDash([4, 4]);
				// ctx.moveTo(activePoint.element.x, chart.chartArea.top);
				// ctx.lineTo(activePoint.element.x, activePoint.element.y);
				// ctx.lineWidth = 1.5;
				// ctx.strokeStyle = 'rgba(23, 93, 208, 0.2)';
				// ctx.stroke();
				// ctx.restore();

				ctx.beginPath();
				ctx.setLineDash([4, 4]);
				ctx.moveTo(activePoint.element.x, activePoint.element.y);
				ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
				ctx.lineWidth = 1.5;
				ctx.strokeStyle = 'rgba(23, 93, 208, 0.5)';
				ctx.stroke();
				ctx.restore();
			}
		},
	};

	const plugins = [tooltipLine];
	const topheaderClass =
		props.subHeader == '' ? 'top-header' : 'top-header has-sub-heading';
	return (
		<div className="alltime-large-card">
			<div className="alltime-large-card-top">
				<div className={topheaderClass}>{props.title}</div>
				{props.subHeader !== '' ? (
					<div className="top-sub-header">{props.subHeader}</div>
				) : (
					''
				)}
			</div>
			<div className="alltime-large-card-header">
				<div>
					<div className="alltime-large-card-header-box">
						<div className="header ">
							<span className="header-text blue-header">
								{allTimeData.toLocaleString()}
							</span>
						</div>
						<span className="header-text-base">
							{props.isAllTime ? 'All time' : 'Past 3 years'}
						</span>
					</div>
				</div>
				<div>
					<div className="alltime-large-card-header-box">
						<div className="header">
							<span className={headerTextClass}>
								{Math.round(percentageChangePerQuarter)}%
							</span>
							<span
								style={arrowBackground}
								className="alltime-large-card-circle-arrow">
								<span
									style={arrowImage}
									className="alltime-large-card-circle-arrow-image"></span>
							</span>
						</div>
						<span className="header-text-base">Per quarter</span>
					</div>
				</div>
			</div>

			<div className="alltime-large-card-content">
				<Line options={options} data={data} plugins={plugins} />
			</div>
		</div>
	);
}

export default LargeCardAllTime;
