import * as React from 'react';
import '../../../styles/SmallCard.css';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import { getMonthName } from '../../../helperFunctions/helpers';

interface Props {
	isUp: boolean;
	total: string;
	stat: number;
	title: string;
	isWide: boolean;
	month: number;
	isCurrent: boolean;
	totalDaysInCurrentMonth: number;
	stroke:number;
}

interface State {}

class SmallCard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			title: props.title,
			stat: props.stat,
			number: props.total,
			isUp: props.isUp,
			isWide: props.isWide,
		};
	}

	render() {
		let arrowVisibility = true;
		let circleBorderColour;
		let arrowImage;
		let arrowBackground;
		//let circleStyle;
		if (this.props.isUp) {
			arrowImage = { backgroundImage: `url(${UpArrow})` };
			arrowBackground = '#1A9D11';
		} else {
			arrowImage = { backgroundImage: `url(${DownArrow})` };
			arrowBackground = '#CC011A';
		}

		let cardWidthSmall;
		if (this.props.isWide) {
			cardWidthSmall = { width: '20vmax' };
		} else {
			cardWidthSmall = { width: '10vmax' };
		}

		let statText = '0';

		if (this.props.stat < 1 && this.props.stat > -1) {
			statText = this.props.stat == 0 ? '0' : '<1';
			arrowVisibility = false;
			circleBorderColour = '#b1b1b1';
		} else {
			statText = Math.abs(Math.round(this.props.stat)).toString();
		}

		// if (this.props.total == '0' && this.props.isCurrent) {
		// 	circleStyle = { width: '150px', borderRadius: '75px' };
		// } else {
		// 	circleStyle = { width: '75px', borderRadius: '50%' };
		// }

		return (
			<div className="small-card" style={cardWidthSmall}>
				<div className="small-card-top">{this.props.title}</div>
				<div style={{ position: 'relative' }}>
				<svg
					className="svg-indicator-sm"
					style={{ position: 'absolute', top: 10, display: this.props.isCurrent &&  this.props.total!== '0'? 'initial' : 'none' }}>
					<circle
						className="svg-indicator-track-sm"
						style={{ stroke: this.props.isCurrent ? '#b1b1b1' : circleBorderColour }}
					/>
					<circle
						className="svg-indicator-indication-sm"
						style={{
							visibility: this.props.isCurrent ? 'visible' : 'hidden',
							strokeDashoffset: this.props.stroke,
							strokeDasharray: 227,
						}}
					/>
				</svg>
				<span className="small-card-circle" >
					<span
						style={{
							backgroundColor: arrowBackground,
							visibility:
								arrowVisibility && !this.props.isCurrent ? 'visible' : 'hidden',
						}}
						className="small-card-circle-arrow">
						<span
							style={arrowImage}
							className="small-card-circle-arrow-image"></span>
					</span>
					<div className="small-card-mid">
						{this.props.isCurrent
							? this.props.total == '0'
								? '-'
								: `${this.props.total}`
							: `${statText}%`}
					</div>
				</span>
				</div>
				<div className="small-card-bottom">
					{this.props.isCurrent
						? this.props.total == '0'
							? 'No Data'
							: `${getMonthName(this.props.month, true)} ${
									this.props.totalDaysInCurrentMonth == 1
										? '1'
										: `1 - ${getMonthName(this.props.month, true)} ${
												this.props.totalDaysInCurrentMonth
										  }`
							  }`
						: `${this.props.total}`}
				</div>
			</div>
		);
	}
}

export default SmallCard;
