import React from 'react';
import { useState } from 'react';
import CoveLogoFullWidth from '../../../components/CoveLogoFullWidth/CoveLogoFullWidth';
import Loading from '../../../components/Loading';
import TopBannerWithoutSwiper from '../../../components/TopBanner/TopBannerWithoutSwiper';
import { getCampaigns } from '../../../environment';
import {
	CampaignListResponse,
	CampaignMonthlyData,
	Campaigns,
} from '../../../models/campaignsListResponse';
import { LoginResponse } from '../../../models/loginResponse';
import './CampaignsPage.scss';
import CardCampaigns from '../components/CardCampaigns/CardCampaigns';
import CardCampaignsMultiLine from '../components/CardCampaigns/CardCampaignsMultiLine';

function CampaignsPage() {
	const [IsLoading, setIsLoading] = useState(false);
	const [campaigns, setCampaigns] = useState<Campaigns[]>([]);
	// const loginResponse: LoginResponse = JSON.parse(
	// 	sessionStorage.getItem('login-response') ?? '{}'
	// );

	// No login required
	// if (!loginResponse.Success) {
	// 	window.location.assign('login');
	// }

	React.useEffect(() => {
		setIsLoading(true);
		fetch(`${getCampaigns}`)
			.then((res) => res.json())
			.then((result: CampaignListResponse) => {
				setIsLoading(false);
				setCampaigns(
					result.Data.sort(
						(b, a) =>
							a.CampaignMonthlyData.reduce(
								(sum, current) => sum + current.TotalHits,
								0
							) -
							b.CampaignMonthlyData.reduce(
								(sum, current) => sum + current.TotalHits,
								0
							)
					)
				);
			});
	}, []);

	if (IsLoading) {
		return <Loading Background="#F5F5F5" />;
	} else {
		return (
			<div style={{ overflowY: 'scroll' }}>
				<TopBannerWithoutSwiper
					Url="/"
					Title="The Cove Campaigns Stats"
					SubTitle="All time data"
				/>

				<div className="campaigns-wrapper">
					<CoveLogoFullWidth />
					<div className="campaign-container-single">
						<CardCampaignsMultiLine
							dataFunction={(monthlyData: CampaignMonthlyData) =>
								monthlyData.TotalHits
							}
							labels={campaigns.map((x) => x.Name)}
							mainData={campaigns.map((x) => x.CampaignMonthlyData)}
							title="Campaign Statistics"
							tooltip="views"
							isCurve={true}
						/>
					</div>
					<div className="campaigns-container">
						{campaigns.map(function (campaign, i) {
							return (
								<CardCampaigns
									rowCount={2}
									key={campaign.Identifier}
									dataFunction={(monthlyData: CampaignMonthlyData) =>
										monthlyData.TotalHits
									}
									url={`/campaign/${campaign.Identifier}`}
									title={`${campaign.Name} >`}
									tooltip="views"
									isCurve={true}
									subHeader=""
									mainData={campaign.CampaignMonthlyData}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default CampaignsPage;
