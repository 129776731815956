import * as React from 'react';
import '../../../styles/TopListCard.css';
import { numberWithCommas } from '../../../helperFunctions/helpers';
import { TopListItem } from '../../../dataService/Interfaces';

interface EnumDataItems extends Array<TopListItem> {}

interface Props {
	title: string;
	smallSpace: boolean;
	data: EnumDataItems;
	useLinks: boolean;
}

interface State {}

class TopListCard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			title: props.title,
			smallSpace: props.smallSpace,
			data: props.data,
			useLinks: props.useLinks,
		};
	}

	render() {
		let lineStyle = { borderBottom: '1px solid #BFBFBF' };
		let maxItems = this.props.data.length - 1;

		let cardStyle;
		let endText: {} | null | undefined;
		let useLinks = this.props.useLinks;

		if (this.props.smallSpace) {
			cardStyle = { width: 'calc(40vmax + 20px)', height: 'auto' };
			endText = 'enrolments';
		} else {
			cardStyle = { width: 'calc(40vmax + 40px)', height: 'auto' };
			endText = 'views';
		}

		return (
			<div className="toplist-card" style={cardStyle}>
				<div className="toplist-card-top">{this.props.title}</div>
				<div className="toplist-inner-cols">
					{this.props.data.map(function (item, i) {
						return (
							<div className="toplist-inner-flex" key={item.id}>
								{useLinks ? (
									<a href={item.link} target="_blank" rel="noreferrer">
										<div className="container-content">
											<img src={item.img} alt="" />
											<div className="toplist-inner-content-flex">
												<div className="toplist-inner-content">{item.text}</div>

												{maxItems === i ? (
													<div className="container-bottom">
														<div className="flex-start">{item.start}</div>
														<div className="flex-end">
															<>
																{numberWithCommas(item.end)} {endText}
															</>
														</div>
													</div>
												) : (
													<div className="container-bottom" style={lineStyle}>
														<div className="flex-start">{item.start}</div>
														<div className="flex-end">
															<>
																{numberWithCommas(item.end)} {endText}
															</>
														</div>
													</div>
												)}
											</div>
										</div>
									</a>
								) : (
									<div className="container-content">
										<img src={item.img} alt="" />
										<div className="toplist-inner-content-flex">
											<div className="toplist-inner-content">{item.text}</div>

											{maxItems === i ? (
												<div className="container-bottom">
													<div className="flex-start">{item.start}</div>
													<div className="flex-end">
														<>
															{numberWithCommas(item.end)} {endText}
														</>
													</div>
												</div>
											) : (
												<div className="container-bottom" style={lineStyle}>
													<div className="flex-start">{item.start}</div>
													<div className="flex-end">
														<>
															{numberWithCommas(item.end)} {endText}
														</>
													</div>
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default TopListCard;
