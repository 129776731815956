import CovePlus from './CovePlus';
import TheCove from './TheCove';
import '../../styles/Dashboard.css';
import TheCoveLogo from '../../assets/images/TheCove-Logo.png';
import CovePlusLogo from '../../assets/images/CovePlus-Logo.png';
import { PageProps } from '../../models/pageProps';
function Dashboard(props: PageProps) {

	return (
		<div className="dashboard-split-view">
			<div className="the-cove-logo-smallscreen">
				<img src={TheCoveLogo} alt="The Cove Logo"></img>
			</div>
			<div className="the-cove-view">
				<TheCove
					Month={props.Month}
					Year={props.Year}
					IsCurrent={props.IsCurrent}
				/>
			</div>
			<div className="cove-plus-logo-smallscreen">
				<img src={CovePlusLogo} alt="Cove Plus Logo"></img>
			</div>
			<div className="cove-plus-view">
				<CovePlus
					Month={props.Month}
					Year={props.Year}
					IsCurrent={props.IsCurrent}
				/>
			</div>
		</div>
	);
}

export default Dashboard;
